@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: revert;
	}

	:root {
		--background: #18181b;
		--background-bg-secondary-80: #27272acc;
		--background-bg-secondary-90: #262629e6;
		--foreground: #000000;
		--highlight: rgba(51, 64, 93, 0.5);
		--card: #33405d;
		--card-foreground: #33405d;
		--card-profile: linear-gradient(90deg, #2196f31a 0%, #2196f300 100%);
		--card-background-80: #09090bcc;
		--card-background-90: #09090be5;
		--card-background-100: #09090b;
		--background-secondary: #27272a;
		--text-primary: #fafafa;
		--text-secondary: #f4f4f5;
		--popover: #18181bcc;
		--popover-foreground: #333333;
		--primary: 240 5.9% 10%;
		--primary-foreground: 0 0% 98%;
		--secondary: 240 4.8% 95.9%;
		--secondary-foreground: 240 5.9% 10%;
		--muted: 240 4.8% 95.9%;
		--muted-foreground: 240 3.8% 46.1%;
		--accent: 240 4.8% 95.9%;
		--accent-foreground: 240 5.9% 10%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 240 5.9% 90%;
		--input: 240 5.9% 90%;
		--ring: 240 10% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;
		--header-height: 78px;
		--sidebar-width: 78px;
	}

	.dark {
		--background: 240 10% 3.9%;
		--foreground: 0 0% 98%;
		--card: 240 10% 3.9%;
		--card-foreground: 0 0% 98%;
		--popover: 240 10% 3.9%;
		--popover-foreground: 0 0% 98%;
		--primary: 0 0% 98%;
		--primary-foreground: 240 5.9% 10%;
		--secondary: 240 3.7% 15.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 240 3.7% 15.9%;
		--muted-foreground: 240 5% 64.9%;
		--accent: 240 3.7% 15.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 0% 98%;
		--border: 240 3.7% 15.9%;
		--input: 240 3.7% 15.9%;
		--ring: 240 4.9% 83.9%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
.step-circle {
	@apply flex h-10 w-10 items-center justify-center rounded-full border;
}

.step-active {
	@apply border-blue-600 bg-blue-600 text-white;
}

.step-inactive {
	@apply border-gray-300 bg-white text-gray-500;
}

@layer utilities {
	.ToUpgradeDAO {
		background: linear-gradient(221deg, #a40271 -17.1%, #1d1b5f 132.26%);
		box-shadow: 0px 0px 10px 0px #f768ca;
		cursor: pointer;
		&:hover {
			box-shadow: 0px 0px 20px 0px #f768ca;
		}
	}

	.body-scrollbar {
		scrollbar-color: #333 #000;
	}

	.custom-scrollbar {
		scrollbar-color: #2196f3 transparent;
	}

	.body-scrollbar::-webkit-scrollbar {
		background-color: #000;
	}

	.custom-scrollbar::-webkit-scrollbar {
		background-color: transparent;
		width: 8px;
		height: 8px;
	}

	.body-scrollbar::-webkit-scrollbar-thumb {
		background-color: #333;
		border-radius: 999px;
		border: 3px solid #000;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		background-color: #2196f3;
		border-radius: 999px;
	}

	.scrollbar-outset::-webkit-scrollbar-thumb {
		position: relative;
		margin-right: 10px;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	.scrollbar-hide {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	.scrollbar-hide::-webkit-scrollbar {
		display: none; /* Chrome, Safari and Opera */
	}
}
